@import '../../../../assets/css/variables';

.event-share-modal {
    &_field {
        display: flex;
    }

    &_label {
        span {
            margin-left: gux();
            color: $silver;
        }
    }

    &_input {
        flex: 1 1;
    }

    &_button {
        margin-left: gux();
    }
}