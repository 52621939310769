@import './colors';

// Media Query Breakpoints
//-------------------------------------//
$screen--mobile: 480px;
$screen--small: 768px;
$screen--medium: 1366px;
$screen--large: 1920px;


// Max-Width Breakpoints
//-------------------------------------//
$max-width--default: 980px;
$max-width--narrow: 800px;


// Fonts and Line Heights
//-------------------------------------//
$font-family--base: 'Open Sans', sans-serif;
$font-family--light: 'Open Sans Light', sans-serif;
$font-family--italic: 'Open Sans Italic', sans-serif;
$font-family--semibold: 'Open Sans Semibold', sans-serif;
$font-family--bold: 'Open Sans Bold', sans-serif;

$font-weight--light: 300;
$font-weight--normal: 400;

$font-size--display-large: 30px;
$font-size--title: 20px;
$font-size--subheader: 18px;
$font-size--base: 13px;
$font-size--caption: 11px;
$font-size--icon: 16px;
$font-size--icon-small: 13px;

$line-height--large: 40px;
$line-height--title: 24px;
$line-height--subheader: 24px;
$line-height--paragraph: 22px;
$line-height--base: 16px;
$line-height--caption: 16px;


// Spacing
//-------------------------------------//

$grid-unit: 8px;

@function gux($multiplier: 1) {
    @return $grid-unit * $multiplier;
}

$site-gutter: gux(4);
$site-header-height: gux(8);
$toolbar-height: gux(8);
$banner-height--default: 130px;

@mixin gutter-padding($topPadding: 0, $bottomPadding: $topPadding) {
    padding: $topPadding $site-gutter $bottomPadding;
}


// Common UI Variables
//-------------------------------------//

$border-radius--xsmall: 2px;
$border-radius--small: 3px;
$border-radius--medium: 5px;
$border-radius--large: 8px;
$border-radius--circle: 50%;

$box-shadow--default: 0 $grid-unit $grid-unit rgba($dark-slate, 0.1);
$box-shadow--light: 0 #{$grid-unit / 4} $grid-unit rgba($black, 0.5);


// Z-Space Management
//-------------------------------------//
$z-index--base: 0;
$z-index--level-one: $z-index--base + 5;
$z-index--level-two: $z-index--base + 10;
$z-index--level-three: $z-index--base + 15; // used for global app header
$z-index--mask: 50;
$z-index--modal-level-one: $z-index--mask + 5;
$z-index--modal-level-two: $z-index--mask + 10;
$z-index--modal-level-three: $z-index--mask + 15; // used for global toast notification


// Animations
//-------------------------------------//
$transition-duration--fast: 0.15s;
$transition-duration--default: 0.3s;
$transition-duration--slow: 0.6s;
$transition-timing-function--default: ease;