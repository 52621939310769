@import '../../../assets/css/colors';
@import '../../../assets/css/variables';

.header {
    @include gutter-padding;
    position: fixed;
    width: 100%;
    height: gux(8);
    display: flex;
    align-items: center;
    background: $q4-blue;
    color: $white;
    z-index: $z-index--level-three;

    a {
        color: inherit;
        text-decoration: none;
    }

    &_q4-icon {
        display: flex;
        justify-content: center;
        margin-right: gux(2);
        width: 50px;
        font-size: 24px;
    }

    &_title {
        margin-right: gux(6);
    }

    &_user-button {
        margin-left: auto;
    }
}
