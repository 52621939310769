@import '../variables';

@mixin font--base-size {
    font-size: $font-size--base;
    line-height: $line-height--base;
}

@mixin font--title-size() {
    font-size: $font-size--title;
    line-height: $line-height--title;
}

@mixin font--subheader-size() {
    font-size: $font-size--subheader;
    line-height: $line-height--subheader;
}