$icomoon-font-family: "q4icons" !default;
$icomoon-font-path: "fonts" !default;

$q4i-logo: "\e9a9";
$q4i-company_2pt: "\ea79";
$q4i-company_4pt: "\ea7a";
$q4i-webcast_2pt: "\ebb4";
$q4i-webcast_4pt: "\ebb5";
$q4i-checkmark_2pt: "\e9d2";
$q4i-checkmark_4pt: "\e9d3";
$q4i-warning_2pt: "\ebaa";
$q4i-warning_4pt: "\ebac";
$q4i-close_2pt: "\ea44";
$q4i-close_4pt: "\ea47";
$q4i-add_2pt: "\e95c";
$q4i-add_4pt: "\e95d";
$q4i-caret-right_2pt: "\e9c0";
$q4i-caret-right_4pt: "\e9c1";
$q4i-caret-left_4pt: "\e9bb";
$q4i-caret-left_2pt: "\e9bc";

