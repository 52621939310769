$q4-font-path: '../q4fonts';

@font-face {
    font-family: 'Open Sans';
    src: url('#{$q4-font-path}/OpenSans-Regular-webfont.eot');
    src: url('#{$q4-font-path}/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$q4-font-path}/OpenSans-Regular-webfont.woff') format('woff'),
    url('#{$q4-font-path}/OpenSans-Regular-webfont.ttf') format('truetype'),
    url('#{$q4-font-path}/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Light';
    src: url('#{$q4-font-path}/OpenSans-Light-webfont.eot');
    src: url('#{$q4-font-path}/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$q4-font-path}/OpenSans-Light-webfont.woff') format('woff'),
    url('#{$q4-font-path}/OpenSans-Light-webfont.ttf') format('truetype'),
    url('#{$q4-font-path}/OpenSans-Light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Italic';
    src: url('#{$q4-font-path}/OpenSans-Italic-webfont.eot');
    src: url('#{$q4-font-path}/OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$q4-font-path}/OpenSans-Italic-webfont.woff') format('woff'),
    url('#{$q4-font-path}/OpenSans-Italic-webfont.ttf') format('truetype'),
    url('#{$q4-font-path}/OpenSans-Italic-webfont.svg#open_sansitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Semibold';
    src: url('#{$q4-font-path}/OpenSans-Semibold-webfont.eot');
    src: url('#{$q4-font-path}/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$q4-font-path}/OpenSans-Semibold-webfont.woff') format('woff'),
    url('#{$q4-font-path}/OpenSans-Semibold-webfont.ttf') format('truetype'),
    url('#{$q4-font-path}/OpenSans-Semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Bold';
    src: url('#{$q4-font-path}/OpenSans-Bold-webfont.eot');
    src: url('#{$q4-font-path}/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$q4-font-path}/OpenSans-Bold-webfont.woff') format('woff'),
    url('#{$q4-font-path}/OpenSans-Bold-webfont.ttf') format('truetype'),
    url('#{$q4-font-path}/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
