@import './assets/css/colors';
@import './assets/css/fonts';
@import './assets/css/variables';
@import './assets/q4icons/style';
@import './assets//css/mixins/font';

/* Box-sizing border-box */
* {
    box-sizing: border-box;
}

/* Properties */
/*======================================*/

html,
body {
    @include font--base-size;
    font-family: $font-family--base;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    color: $light-slate;
    background-color: $slate;
}

h1,
h2,
h3,
h4 {
    margin: 0;
    font-weight: normal;
}

p {
    margin: 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

*:focus {
    outline: none;
}

input::-ms-clear {
    display: none;
}

.overflow-hidden {
    overflow: hidden;
}