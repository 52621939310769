@import '../../assets/css/variables';

.logout {
    padding-top: $site-header-height;
    height: 100vh;

    .nui-placeholder-content {
        &_actions {
            margin-top: gux(3);
        }
    }
}
