@import '../../assets/css/variables';

$control-size: 64px;

.slide-viewer {
    height: 100%;
    position: relative;

    &:hover {
        .slide-viewer_controls {
            opacity: 1;
        }
    }
    .slide-viewer {
        &_controls {
            transition: opacity $transition-duration--default ease-in-out;
            opacity: 0;
        }

        &_control {
            border-radius: $border-radius--circle;
            position: absolute;
            top: calc(50% - #{$control-size / 2});
            width: $control-size;
            height: $control-size;
            cursor: pointer;
            background-color: rgba($white, 0.5);
            box-shadow: $box-shadow--default;

            &:hover {
                background-color: rgba($white, 0.8);
            }

            &:focus {
                background-color: $white;
            }

            &--right {
                right: gux(2);

                .nui-button_icon {
                    margin-left: 4px;
                }
            }

            &--left {
                left: gux(2);

                .nui-button_icon {
                    margin-right: 4px;
                }
            }

            .nui-button_icon {
                color: $slate;
                font-size: 40px;
            }
        }
    }

    .react-pdf {
        &__Document,
        &__message--error,
        &__message--no-data {
            height: 100%;
        }

        &__Page {
            display: flex;
            align-items: center;
            justify-content: center;
            height:  100%;
            background-color: $black-smoke;

            &__annotations,
            &__textContent {
                display: none;
            }
        }
    }
}