@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?fylfkc') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?fylfkc') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?fylfkc') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?fylfkc##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="q4i-"], [class*=" q4i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.q4i-logo {
  &:before {
    content: $q4i-logo; 
  }
}
.q4i-company_2pt {
  &:before {
    content: $q4i-company_2pt; 
  }
}
.q4i-company_4pt {
  &:before {
    content: $q4i-company_4pt; 
  }
}
.q4i-webcast_2pt {
  &:before {
    content: $q4i-webcast_2pt; 
  }
}
.q4i-webcast_4pt {
  &:before {
    content: $q4i-webcast_4pt; 
  }
}
.q4i-checkmark_2pt {
  &:before {
    content: $q4i-checkmark_2pt; 
  }
}
.q4i-checkmark_4pt {
  &:before {
    content: $q4i-checkmark_4pt; 
  }
}
.q4i-warning_2pt {
  &:before {
    content: $q4i-warning_2pt; 
  }
}
.q4i-warning_4pt {
  &:before {
    content: $q4i-warning_4pt; 
  }
}
.q4i-close_2pt {
  &:before {
    content: $q4i-close_2pt; 
  }
}
.q4i-close_4pt {
  &:before {
    content: $q4i-close_4pt; 
  }
}
.q4i-add_2pt {
  &:before {
    content: $q4i-add_2pt; 
  }
}
.q4i-add_4pt {
  &:before {
    content: $q4i-add_4pt; 
  }
}
.q4i-caret-right_2pt {
  &:before {
    content: $q4i-caret-right_2pt; 
  }
}
.q4i-caret-right_4pt {
  &:before {
    content: $q4i-caret-right_4pt; 
  }
}
.q4i-caret-left_4pt {
  &:before {
    content: $q4i-caret-left_4pt; 
  }
}
.q4i-caret-left_2pt {
  &:before {
    content: $q4i-caret-left_2pt; 
  }
}

