@import '../../../assets/css/colors';
@import '../../../assets/css/variables';

.page-layout {
    padding-top: $site-header-height;

    .page-layout {
        &_spinner {
            display: flex;
            align-items: center;
            justify-content: center;
            position: static;
            top: auto;
            right: auto;
            bottom: auto;
            width: 100%;
            height: calc(100vh - #{$site-header-height});
        }

        &_content {
            height: calc(100vh - #{$site-header-height + $banner-height--default});

            &-inner {
                height: 100%;
            }
        }
    }
}