//== Colors

$deep-blue: #0A457C;
$midnight: #053a6b;
$ink: #10528f;
$q4-blue: #0f5ca3;
$steel: #1f6bb0;
$rain: #297ac5;
$surf: #4696E0;
$olympic: #428bcf;
$ice: #94e9f6;
$teal: #1abc9c;
$dark-teal: #0da084;
$lime: #2ecc71;
$avocado: #3a925f;
$sunshine: #f1c40f;
$citrus: #f1af0f;
$mustard: #dc9e27;
$tangerine: #fc7e26;
$ginger: #e67f22;
$amber: #d95608;
$spice: #ec6a4c;
$cherry: #e74C3c;
$apple: #aa2d40;
$raspberry: #a4305e;
$eggplant: #6a3476;
$plum: #804a8c;
$black: #000000;
$black-smoke: #121517;
$dark-slate: #22272b;
$slate: #2a3035;
$titanium: #373B41;
$light-slate: #545b62;
$silver: #939ba0;
$ash: #cccccc;
$ghost: #dddddd;
$soft-grey: #e0e0e0;
$light-grey: #eeeeee;
$white: #ffffff;
$walnut: #935f39;