@import '../../assets/css/variables';
@import '../../assets/css/colors';
@import '../../assets/css/animations';

.client-list-page {
    &_grid {
        position: relative;
        height: calc(100% - #{$toolbar-height});
        background-color: $white;

        & > div {
            animation: fade-in $transition-duration--default $transition-timing-function--default;
        }
    }
}