@import '../../../assets/css/colors';
@import '../../../assets/css/variables';
@import '../../../assets/css/mixins/font';

.event-details {
    &_viewer {
        height: 100%;
        background-color: $white;
        padding: gux(4) gux(11);

        &-content {
            padding: gux(.5) gux(3) gux(3);
            box-shadow: 0 3px 24px 0 rgba($black, 0.1);
            height: 100%;

            &--loaded {
                padding: 0;
            }
        }

        .nui-swapable,
        .nui-swapable_layer {
            height: inherit;
        }
    }
}
