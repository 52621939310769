.viewer {
    width: 100vw;
    height: 100vh;
    background-color: white;

    .nui-swapable,
    .nui-swapable_layer {
        height: 100%;
    }

    .nui-placeholder-content {
        &_title,
        &_subtitle {
            max-width: none;
        }
    }
}