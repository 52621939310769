@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes pop-in {
    from {
        transform: scale(0.5);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes pop-out {
    from {
        transform: scale(1);
        opacity: 1;
    }
    to {
        transform: scale(0.5);
        opacity: 0;
    }
}

@keyframes loading-pulse {
    0% {
        -webkit-transform: scale(0.1);
        transform: scale(0.1);
        opacity: 1;
    }
    70% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0.7;
    }
    100% {
        opacity: 0.0;
    }
}