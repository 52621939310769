@import '../../assets/css/variables';

.text-with-count {
  &_count {
    margin-left: gux(0.5);

    &:before {
      content: '(';
    }
    &:after {
      content: ')';
    }
  }
}